import { metricsEvents } from '../constants/metricsConstants';
// import { infoLog } from '@bit/gerenciatd.utils.log-utils';

/* global IC:true */

class EventManager {
  constructor(getContextFunc) {
    this.getContext = getContextFunc;
    this.componentsICEvents = {};
  }

  dispatchNewEvent(msg) {
    // infoLog(JSON.stringify(msg));
    switch (msg.event) {
      case metricsEvents.EVENT:
        this.__manageUIEvents(msg);
        break;
      case metricsEvents.PAGE:
        this.__managePageEvents(msg);
        break;
      default:
        this.__manageUIEvents(msg);
    }
  }

  __managePageEvents(msg) {
    // Si se requiere el contexto para añadir más información al dataLayer se usa this.getContext
    // const updatedContext = this.getContext();

    // infoLog(JSON.stringify(updatedContext));
    // infoLog(JSON.stringify(msg));

    if (msg.event === 'cms-page') {
      if (msg['event-type'] === 'view') {
        const componentUuid = msg['context-page-id'];
        const newEvent = IC.createEventObj({
          event: msg['event'],
          context: 'CMS-PAGES'
        });

        this.componentsICEvents[componentUuid] = newEvent;

        IC.trackEventObj({
          eventType: 'Started',
          type: 'pageview',
          ...newEvent
        });
      } else if (msg['event-type'] === 'printed') {
        const newEvent = this.componentsICEvents[msg['context-page-id']];

        if (newEvent) {
          IC.trackEventObj({
            eventType: 'Loaded',
            status: 'ok',
            cData: msg['full-page-print'],
            type: 'pageview',
            ...newEvent
          });

          delete this.componentsICEvents[msg['context-page-id']];
        }
      }
    }

    window.dataLayerGTM.push(msg);
  }

  __manageUIEvents(msg) {
    // Si se requiere el contexto para añadir más información al dataLayer se usa this.getContext
    // const updatedContext = this.getContext();

    // infoLog(JSON.stringify(updatedContext));
    // infoLog(JSON.stringify(msg));

    if (msg.event === 'cms-component' && msg['component-name'] !== 'header') {
      if (msg['event-type'].indexOf('fetching') !== -1) {
        const componentUuid = msg['component-uuid'];
        const newEvent = IC.createEventObj({
          event: msg['component-name'],
          context: 'COMPONENTS'
        });

        this.componentsICEvents[componentUuid] = newEvent;

        IC.trackEventObj({
          eventType: 'Started',
          type: 'component',
          ...newEvent
        });
      } else if (msg['event-type'].indexOf('error') !== -1) {
        const newEvent = this.componentsICEvents[msg['component-uuid']];

        if (newEvent) {
          IC.trackEventObj({
            eventType: 'Loaded',
            status: 'error',
            cData: msg['component-error-detail'],
            type: 'component',
            ...newEvent
          });

          delete this.componentsICEvents[msg['component-uuid']];
        }
      } else if (msg['event-type'].indexOf('success') !== -1) {
        const newEvent = this.componentsICEvents[msg['component-uuid']];

        if (newEvent) {
          IC.trackEventObj({
            eventType: 'Loaded',
            status: 'ok',
            cData: '',
            type: 'component',
            ...newEvent
          });

          delete this.componentsICEvents[msg['component-uuid']];
        }
      }
    }

    window.dataLayerGTM.push(msg);
  }

}

export default EventManager;
