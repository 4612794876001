import { METRICS_QUEUE, METRICS_OBSERVER, CONTEXT_QUEUE } from '../constants/queueConstants';
import EventManager from '../EventManager/EventManager';
import ContextManager from '../ContextManager/ContextManager';

/* global nookInfoExchange:true */

class MetricsCore {
  constructor() {
    if (typeof nookInfoExchange !== 'undefined') {
      this.contextManager = new ContextManager();
      nookInfoExchange.registerAsObserver(CONTEXT_QUEUE, METRICS_OBSERVER, (msg) => {
        this.contextManager.newContextReceived(msg);
      });

      this.eventManager = new EventManager(this.contextManager.getContextInfo);
      nookInfoExchange.registerAsObserver(METRICS_QUEUE, METRICS_OBSERVER, (msg) => {
        this.eventManager.dispatchNewEvent(msg);
      });
    }
  }
}

export default MetricsCore;
