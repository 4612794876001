// import { infoLog } from '@bit/gerenciatd.utils.log-utils';

class EventManager {
  constructor() {
    this.__contextInfo = {};
    this.getContextInfo = this.getContextInfo.bind(this);
  }

  newContextReceived(newContext) {
    this.__contextInfo = newContext;
  }

  getContextInfo() {
    return JSON.parse(JSON.stringify(this.__contextInfo));
  }
}

export default EventManager;
